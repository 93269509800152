<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'GroupsConnections',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    FilterListSearch: () => import('@/components/general/FilterListSearch')
  },

  data () {
    return {
      tabLinks: [
        {
          alias: 'user',
          text: 'groups.manage:tabs.index.1'
        },
        {
          alias: 'solution',
          text: 'groups.manage:tabs.index.2'
        },
        {
          alias: 'path',
          text: 'groups.manage:tabs.index.3'
        }
      ],

      tabLinkActive: 'user',
      groupId: null,
      modalRemoveOpen: false,

      selectedConnection: null,
      selectedGroup: null,

      userPagination: {
        filter: {
          id_group: null,
          active: true
        },

        order: {
          created_at: 'desc'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6
      },

      solutionPagination: {
        filter: {
          id_group: null,
          active: true
        },

        order: {
          created_at: 'desc'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6
      },

      pathPagination: {
        filter: {
          id_group: null,
          active: true
        },

        order: {
          created_at: 'desc'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6
      },

      programPagination: {
        filter: {
          id_group: null,
          active: true
        },

        order: {
          created_at: 'desc'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6
      },

      thematicPagePagination: {
        filter: {
          id_group: null,
          active: true
        },

        order: {
          created_at: 'desc'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    ...mapGetters([
      'activeModules',
      'groupsUsers',
      'groupsSolutions',
      'groupsLearningPaths',
      'groupsPrograms',
      'groupsThematicPages'
    ]),

    getGroupName () {
      return this.selectedGroup ? this.selectedGroup.name : ''
    },

    isProgramEnabled () {
      return this.activeModules.includes('program')
    },

    isThematicPageEnabled () {
      return this.activeModules.includes('thematic-page')
    },

    shouldShowEmptyMessage () {
      if (this.tabLinkActive === 'user') {
        return this.groupsUsers ? this.groupsUsers.length === 0 : true
      } else if (this.tabLinkActive === 'solution') {
        return this.groupsSolutions ? this.groupsSolutions.length === 0 : true
      } else if (this.tabLinkActive === 'program') {
        return this.groupsPrograms ? this.groupsPrograms.length === 0 : true
      } else if (this.tabLinkActive === 'path') {
        return this.groupsLearningPaths ? this.groupsLearningPaths.length === 0 : true
      } else if (this.tabLinkActive === 'thematic_page') {
        return this.groupsThematicPages ? this.groupsThematicPages.length === 0 : true
      }

      return true
    }
  },

  watch: {
    'userPagination.page' () {
      this.getGroupsUsers()
    },

    'pathPagination.page' () {
      this.getGroupsPaths()
    },

    'solutionPagination.page' () {
      this.getGroupsSolutions()
    },

    'programPagination.page' () {
      this.getGroupsPrograms()
    },

    'thematicPagePagination.page' () {
      this.getGroupsThematicPages()
    }
  },

  created () {
    if (this.isThematicPageEnabled) {
      this.tabLinks.push({
        alias: 'thematic_page',
        text: 'groups.manage:tabs.index.4',
        disabled: !this.isThematicPageEnabled
      })
    }

    if (this.isProgramEnabled) {
      this.tabLinks.push({
        alias: 'program',
        text: 'groups.manage:tabs.index.5',
        disabled: !this.isProgramEnabled
      })
    }

    this.groupId = this.$route.params.id
    this.tabLinkActive = this.$route.params.type
    this.setFetching(true)
    this.clearAllGroupsConnections()

    this.attemptGetGroup(this.groupId)
      .then(data => {
        this.selectedGroup = data.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setFetching(false)
        this.changeTab(this.tabLinkActive)
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetGroup',
      'attemptGetGroupsUsers',
      'attemptGetGroupsSolutions',
      'attemptGetGroupsLearningPaths',
      'attemptGetGroupsPrograms',
      'attemptGetGroupsThematicPages',
      'attemptRemoveConnection',
      'clearAllGroupsConnections'
    ]),

    prevPage (pagination) {
      if (pagination.page > 1) {
        pagination.page -= 1
      }
    },

    nextPage (pagination) {
      pagination.page += 1
    },

    firstPage (pagination) {
      pagination.page = 1
    },

    lastPage (pagination) {
      pagination.page = pagination.lastPage
    },

    changeTab (index) {
      this.tabLinkActive = index

      this.$router.replace({
        params: {
          id: this.groupId,
          type: this.tabLinkActive
        }
      })

      if (index === 'user') {
        this.userPagination.filter.id_group = this.groupId
        this.getGroupsUsers()
      } else if (index === 'solution') {
        this.solutionPagination.filter.id_group = this.groupId
        this.getGroupsSolutions()
      } else if (index === 'path') {
        this.pathPagination.filter.id_group = this.groupId
        this.getGroupsPaths()
      } else if (index === 'program') {
        this.programPagination.filter.id_group = this.groupId
        this.getGroupsPrograms()
      } else if (index === 'thematic_page') {
        this.thematicPagePagination.filter.id_group = this.groupId
        this.getGroupsThematicPages()
      }
    },

    leave () {
      this.$router.push({ name: 'groups.index' })
    },

    getGroupsUsers () {
      this.setFetching(true)

      this.attemptGetGroupsUsers(this.userPagination)
        .then((pagination) => {
          this.$set(this.userPagination, 'lastPage', pagination.lastPage)
          this.userPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getGroupsSolutions () {
      this.setFetching(true)

      this.attemptGetGroupsSolutions(this.solutionPagination)
        .then((pagination) => {
          this.$set(this.solutionPagination, 'lastPage', pagination.lastPage)
          this.solutionPagination.actualPage = pagination.page
        })
        .catch(data => {
          const errorMsg = data.data.error.message

          this.setFeedback({ message: this.$t(`groups:feedback.${errorMsg}`) })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getGroupsPaths () {
      this.setFetching(true)

      this.attemptGetGroupsLearningPaths(this.pathPagination)
        .then((pagination) => {
          this.$set(this.pathPagination, 'lastPage', pagination.lastPage)
          this.pathPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getGroupsPrograms () {
      this.setFetching(true)

      this.attemptGetGroupsPrograms(this.programPagination)
        .then((pagination) => {
          this.$set(this.programPagination, 'lastPage', pagination.lastPage)
          this.programPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getGroupsThematicPages () {
      this.setFetching(true)

      this.attemptGetGroupsThematicPages(this.thematicPagePagination)
        .then((pagination) => {
          this.$set(this.thematicPagePagination, 'lastPage', pagination.lastPage)
          this.thematicPagePagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    goToNewConnections () {
      this.$router.push({
        name: 'groups.connections.new',
        params: {
          type: this.tabLinkActive,
          group: this.selectedGroup
        }
      })
    },

    getTabUrlName (tab) {
      const tabUrlNames = {
        user: 'user',
        path: 'learning_path',
        solution: 'solution',
        program: 'program',
        thematic_page: 'thematic_page'
      }

      return tabUrlNames[tab]
    },

    removeConnection () {
      this.setFetching(true)

      this.attemptRemoveConnection({
        groupId: this.groupId,
        tab: this.getTabUrlName(this.tabLinkActive),
        connectionId: this.selectedConnection
      })
        .then(() => {
          this.setFeedback({ message: this.$t('groups:feedback.remove.success') })
        })
        .finally(() => {
          this.setFetching(false)
          this.cancelRemoveConnection()
          this.changeTab(this.tabLinkActive)
        })
    },

    openRemoveConnectionModal (id) {
      this.selectedConnection = id
      this.modalRemoveOpen = true
    },

    cancelRemoveConnection () {
      this.modalRemoveOpen = false
    },

    searchConnectionUser (search) {
      this.userPagination.page = 1

      this.userPagination.query = {
        name: search
      }

      this.getGroupsUsers()
    },

    searchConnectionPath (search) {
      this.pathPagination.page = 1

      this.pathPagination.query = {
        name: search
      }

      this.getGroupsPaths()
    },

    searchConnectionSolution (search) {
      this.solutionPagination.page = 1

      this.solutionPagination.query = {
        name: search
      }

      this.getGroupsSolutions()
    },

    searchConnectionProgram (search) {
      this.programPagination.page = 1

      this.programPagination.query = {
        name: search
      }

      this.getGroupsPrograms()
    },

    searchConnectionThematicPage (search) {
      this.thematicPagePagination.page = 1

      this.thematicPagePagination.query = {
        name: search
      }

      this.getGroupsThematicPages()
    }
  }

}
</script>

<template>
  <Modal
    :active="true"
    :cancel-label="$t('global:back')"
    @close="leave()"
  >
    <div class="modal-form modal-group-connections">
      <span class="modal-subtitle">{{ $t('groups.connections:header.title') }}</span>

      <h2 class="modal-title">
        {{ getGroupName }}
      </h2>

      <p class="modal-description">
        {{ $t(`groups.connections:header.description.${tabLinkActive}`) }}
      </p>

      <Tabs
        slot="tabs"
        :links="tabLinks"
        :alias-active="tabLinkActive"
        dark
        use-alias
        @tabChange="changeTab"
      />

      <form @submit.prevent>
        <template v-if="tabLinkActive === 'user' && groupsUsers && groupsUsers.length >= 0">
          <Action
            type="button"
            primary
            dark
            :text="$t('groups.manage:btn.create.connection.user')"
            class="btn-create-connection"
            @click="goToNewConnections"
          />

          <FilterListSearch
            slot="search"
            dark
            :hide-error-details="true"
            @search="searchConnectionUser"
          />

          <Datatable
            v-if="groupsUsers.length > 0"
            class="groups-users-list"
            :items="groupsUsers"
            dark
          >
            <template slot="thead">
              <tr>
                <th
                  class="th"
                  width="400"
                >
                  {{ $t('groups.connections.list:datatable.header.1') }}
                </th>

                <th
                  class="th"
                  width="140"
                >
                  {{ $t('groups.connections.list:datatable.header.2') }}
                </th>

                <th
                  class="th"
                  width="30"
                />
              </tr>
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr v-if="groupsUsers.length">
                <td
                  class="td"
                  width="400"
                >
                  <span class="td-text bolder">{{ props.item.user.name }}</span>
                </td>

                <td
                  class="td"
                  width="140"
                >
                  <span class="td-text">{{ props.item.user.username }}</span>
                </td>

                <td
                  class="td text-center"
                  width="30"
                >
                  <Action
                    class="remove-connection"
                    type="button"
                    uppercase
                    primary
                    flat
                    dark
                    tiny
                    :text="$t('global:remove')"
                    @click="openRemoveConnectionModal(props.item.user.id)"
                  />
                </td>
              </tr>

              <tr v-else>
                {{ $t('global:datatable.no.result') }}
              </tr>
            </template>
          </Datatable>

          <Pagination
            :active-page="userPagination.page"
            :page-count="userPagination.lastPage"
            dark
            @firstPage="firstPage(userPagination)"
            @lastPage="lastPage(userPagination)"
            @nextPage="nextPage(userPagination)"
            @previousPage="prevPage(userPagination)"
          />
        </template>

        <template v-if="tabLinkActive === 'solution' && groupsSolutions && groupsSolutions.length >= 0">
          <Action
            type="button"
            primary
            dark
            :text="$t('groups.manage:btn.create.connection.solution')"
            class="btn-create-connection"
            @click="goToNewConnections"
          />

          <FilterListSearch
            slot="search"
            dark
            :hide-error-details="true"
            @search="searchConnectionSolution"
          />

          <Datatable
            v-if="groupsSolutions.length > 0"
            :items="groupsSolutions"
            dark
          >
            <template slot="thead">
              <tr>
                <th
                  class="th"
                  width="400"
                >
                  {{ $t('groups.connections.list:datatable.header.1') }}
                </th>

                <th
                  class="th"
                  width="30"
                />
              </tr>
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td
                  class="td"
                  width="400"
                >
                  <span class="td-text bolder">{{ props.item.solution.name }}</span>
                </td>

                <td
                  class="td text-center"
                  width="30"
                >
                  <Action
                    class="remove-connection"
                    type="button"
                    uppercase
                    primary
                    flat
                    dark
                    tiny
                    :text="$t('global:remove')"
                    @click="openRemoveConnectionModal(props.item.solution.id)"
                  />
                </td>
              </tr>
            </template>
          </Datatable>

          <Pagination
            :active-page="solutionPagination.page"
            :page-count="solutionPagination.lastPage"
            dark
            @firstPage="firstPage(solutionPagination)"
            @lastPage="lastPage(solutionPagination)"
            @nextPage="nextPage(solutionPagination)"
            @previousPage="prevPage(solutionPagination)"
          />
        </template>

        <template v-if="tabLinkActive === 'path' && groupsLearningPaths && groupsLearningPaths.length >= 0">
          <Action
            type="button"
            primary
            dark
            :text="$t('groups.manage:btn.create.connection.path')"
            class="btn-create-connection"
            @click="goToNewConnections"
          />

          <FilterListSearch
            slot="search"
            dark
            :hide-error-details="true"
            @search="searchConnectionPath"
          />

          <Datatable
            v-if="groupsLearningPaths.length > 0"
            :items="groupsLearningPaths"
            dark
          >
            <template slot="thead">
              <tr>
                <th
                  class="th"
                  width="400"
                >
                  {{ $t('groups.connections.list:datatable.header.1') }}
                </th>

                <th
                  class="th"
                  width="30"
                />
              </tr>
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td
                  class="td"
                  width="400"
                >
                  <span class="td-text bolder">{{ props.item.learningPath.name }}</span>
                </td>

                <td
                  class="td text-center"
                  width="30"
                >
                  <Action
                    class="remove-connection"
                    type="button"
                    uppercase
                    primary
                    flat
                    dark
                    tiny
                    :text="$t('global:remove')"
                    @click="openRemoveConnectionModal(props.item.learningPath.id)"
                  />
                </td>
              </tr>
            </template>
          </Datatable>

          <Pagination
            :active-page="pathPagination.page"
            :page-count="pathPagination.lastPage"
            dark
            @firstPage="firstPage(pathPagination)"
            @lastPage="lastPage(pathPagination)"
            @nextPage="nextPage(pathPagination)"
            @previousPage="prevPage(pathPagination)"
          />
        </template>

        <template v-if="tabLinkActive === 'program' && groupsPrograms && groupsPrograms.length >= 0">
          <Action
            type="button"
            primary
            dark
            :text="$t('groups.manage:btn.create.connection.program')"
            class="btn-create-connection"
            @click="goToNewConnections"
          />

          <FilterListSearch
            slot="search"
            dark
            :hide-error-details="true"
            @search="searchConnectionProgram"
          />

          <Datatable
            v-if="groupsPrograms.length > 0"
            :items="groupsPrograms"
            dark
          >
            <template slot="thead">
              <th
                class="th"
                width="400"
              >
                {{ $t('groups.connections.list:datatable.header.1') }}
              </th>

              <th
                class="th"
                width="30"
              />
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td
                  class="td"
                  width="400"
                >
                  <span class="td-text bolder">{{ props.item.program.name }}</span>
                </td>

                <td
                  class="td text-center"
                  width="30"
                >
                  <Action
                    class="remove-connection"
                    type="button"
                    uppercase
                    primary
                    flat
                    dark
                    tiny
                    :text="$t('global:remove')"
                    @click="openRemoveConnectionModal(props.item.program.id)"
                  />
                </td>
              </tr>
            </template>
          </Datatable>

          <Pagination
            :active-page="programPagination.page"
            :page-count="programPagination.lastPage"
            dark
            @firstPage="firstPage(programPagination)"
            @lastPage="lastPage(programPagination)"
            @nextPage="nextPage(programPagination)"
            @previousPage="prevPage(programPagination)"
          />
        </template>

        <template v-if="tabLinkActive === 'thematic_page' && groupsThematicPages && groupsThematicPages.length >= 0">
          <Action
            type="button"
            primary
            dark
            :text="$t('groups.manage:btn.create.connection.thematicPage')"
            class="btn-create-connection"
            @click="goToNewConnections"
          />

          <FilterListSearch
            slot="search"
            dark
            :hide-error-details="true"
            @search="searchConnectionThematicPage"
          />

          <Datatable
            v-if="groupsThematicPages.length > 0"
            :items="groupsThematicPages"
            dark
          >
            <template slot="thead">
              <th
                class="th"
                width="400"
              >
                {{ $t('groups.connections.list:datatable.header.1') }}
              </th>

              <th
                class="th"
                width="30"
              />
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td
                  class="td"
                  width="400"
                >
                  <span class="td-text bolder">{{ props.item.thematicPage.name }}</span>
                </td>

                <td
                  class="td text-center"
                  width="30"
                >
                  <Action
                    class="remove-connection"
                    type="button"
                    uppercase
                    primary
                    flat
                    dark
                    tiny
                    :text="$t('global:remove')"
                    @click="openRemoveConnectionModal(props.item.thematicPage.id)"
                  />
                </td>
              </tr>
            </template>
          </Datatable>

          <Pagination
            :active-page="thematicPagePagination.page"
            :page-count="thematicPagePagination.lastPage"
            dark
            @firstPage="firstPage(thematicPagePagination)"
            @lastPage="lastPage(thematicPagePagination)"
            @nextPage="nextPage(thematicPagePagination)"
            @previousPage="prevPage(thematicPagePagination)"
          />
        </template>

        <template v-if="shouldShowEmptyMessage">
          <p class="modal-list__empty-message">
            {{ $t('groups.connections:empty.message') }}
          </p>
        </template>
      </form>
    </div>

    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('groups.connections:remove.title')"
      :description="$t(`groups.connections:remove.description.${tabLinkActive === 'user' ? tabLinkActive : 'default'}`)"
      has-multiple-paragraphs
      :z-ind="104"
      @confirmAction="removeConnection"
      @cancelAction="cancelRemoveConnection"
    />
  </Modal>
</template>

<style lang="scss">
.modal-group-connections.modal-form {
  p {
    font-style: italic;
    max-width: 590px;
    color: white;
    margin: 0 auto;
  }

  form {
    max-width: 590px;

    .modal-list__empty-message {
      font-style: italic;
      margin-top: 24px;
      color: white;
    }
  }

  .filter-search {
    float: right;
  }

  .filter-search-form {
    margin-top: 0;
  }
}
</style>
